.ol-map {
  height: 100%;
  width: 100%;
}

.ol-map button {
  font-size: 1.15em;
  cursor: pointer;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
  bottom: auto;
  left: auto;
  right: 15px;
  top: 15px;
}

.ol-geolocation {
  right: 15px;
  top: 100px;
}

.ol-control-switcher {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
  bottom: auto;
  left: auto;
  right: 15px;
  top: 75px;
}

.ol-attribution {
  display: none;
}
